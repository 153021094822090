import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Eyecatch = ({ slug, alt, className }) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPost {
          nodes {
            featured_media {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 855) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            slug
          }
        }
      }
    `}
    render={data => {
      const image = data.allWordpressPost.nodes.filter(n => n.slug === slug)

      if (!image) return

      return (
        <Img
          fluid={image[0].featured_media.localFile.childImageSharp.fluid}
          alt={alt}
          className={className}
          loading="eager"
          durationFadeIn={100}
        />
      )
    }}
  />
)
export default Eyecatch
