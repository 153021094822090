import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ alt, style }) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "noimage.png" }) {
          childImageSharp {
            sizes(maxWidth: 855) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `}
    render={data => (
      <Img sizes={data.file.childImageSharp.sizes} alt={alt} style={style} />
    )}
  />
)
export default Image
